import React from 'react';

const FallbackComponent = () => {
    return (
        <div className="h-dvh flex items-center justify-center">
            <h1 className="text-4xl font-bold">Error: 404 <p>Page not found</p></h1>
        </div>
    );
};

export default FallbackComponent;
