import React from 'react'
import pmImage from '../../assets/images/pm-home.png'
import { useLocation } from 'react-router-dom';

const SummaryComponent = ({ activeSummary }) => {
    const { id, header, summary } = activeSummary;

    const { pathname } = useLocation();

    return (
        <main className={`container-fluid flex mx-auto gap-20 sm:pt-40 xl:pt-16 2xl:pt-28 justify-center ${id === 2 ? 'justify-center sm:pt-24 2xl:mx-52' : 'lg:mr-32 xl:mr-80 pt-4 sm:pt-10'}`} >
            {id === 2 && <img className="hidden sm:block aspect-square my-auto lg:max-w-72 xl:max-w-52 2xl:max-w-72 h-auto" src={pmImage} alt="Phaneesh Murthy" />}
            <section className={`flex flex-col box-content w-5/6 ${id === 2 ? ' 2xl:w-3/6 xl:w-2/6 lg:w-3/6' : 'sm:ml-20 xl:w-3/6 lg:w-3/6'} gap-2 sm:gap-5`}>
                {id === 2 && <img className="block sm:hidden aspect-square m-auto max-w-36 h-auto" src={pmImage} alt="Phaneesh Murthy" />}
                <div className='text-justify sm:text-left'>
                    <h2 className={`hidden mt-10 font-bold  ${id === 2 ? 'text-800' : 'text-400'}`}>
                        {id === 2 ? (
                            'Phaneesh Murthy'
                        ) : (
                            <>
                                <span className="font-gotham-black rotate-180 translate-y-5 hidden lg:inline-block text-700">
                                    &nbsp;,,</span>
                                {header}
                                <span className="font-gotham-black -translate-y-1 hidden lg:inline-block text-700 leading-[.1rem]">
                                    &nbsp;,,
                                </span>
                            </>
                        )}
                    </h2>
                    <span className='font-bold text-2xl md:text-3xl leading-6 md:leading-9 tracking-tight'>
                        <h1 className="font-gotham-black rotate-180 translate-y-1 md:translate-y-3 text-yellow text-600 md:text-700 inline-block">
                            &nbsp;,,</h1>
                        <span className=''>{header}</span>
                        <h1 className="font-gotham-black -translate-y-3 text-yellow text-600 md:text-700 inline-block">
                            &nbsp;,,
                        </h1>
                    </span>
                </div>

                <p className={`pt-5 text-justify pr-3 sm:text-justify 2xl:text-300 lg:text-200 text-100 ${pathname === '/' ? 'max-h-40 mb-5 sm:max-h-36 2xl:max-h-54 xl:max-h-40 lg:max-h-40' : 'max-h-48 mb-5 sm:max-h-36 2xl:max-h-54 xl:max-h-40 lg:max-h-46'} ${pathname === '/love-for-adventure' ? 'max-h-32 pt-2' : pathname === '/love-for-golf' ? 'max-h-44' : ''} overflow-hidden overflow-y-auto scroll-smooth whitespace-pre-line sm:pr-4`}>
                    {summary}
                </p>
            </section>
        </main >
    )
}

export default SummaryComponent