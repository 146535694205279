import React from 'react';
import { useParams } from 'react-router-dom';
import { blogPosts } from '../../_blogPosts';
import { formatDate } from '../../utils/formatDate'

const BlogPostPage = () => {
    const { slug } = useParams();
    const post = blogPosts.items.find(post => post.title.replace(/\s+/g, '-').toLowerCase() === slug);

    if (!post) {
        return <p>Post not found.</p>;
    }

    return (
        <div className="container mx-auto my-8">
            <header>
                <h1 className="text-3xl font-bold mb-4">{post.title}</h1>
                <div className="text-gray-500 mb-4">
                    <time dateTime={post.date_published}>
                        Published on {formatDate(post.date_published)}
                    </time>
                    <span> by {post.author?.name || ''}</span>
                </div>
            </header>
            <div
                className="prose"
                dangerouslySetInnerHTML={{ __html: post.content_html || '' }}
            />
        </div>
    );
};

export default BlogPostPage;
