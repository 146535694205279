import React from 'react';
import { Link } from 'react-router-dom';
import { blogPosts } from '../_blogPosts';
import { formatDate } from '../utils/formatDate';

const BlogPage = () => {
    return (
        <div className="container mx-auto my-8">
            <header>
                <h1 className="text-3xl font-bold mb-4">Blog</h1>
            </header>
            <main>
                {blogPosts.items.map((post, index) => (
                    <article key={post.guid || index} className="mb-8">
                        <header>
                            <h2 className="text-2xl font-bold mb-2">
                                <Link to={`/blogPosts/${post.title.replace(/\s+/g, '-').toLowerCase()}`}>
                                    {post.title}
                                </Link>
                            </h2>
                            <div className="text-gray-500 mb-4">
                                <time dateTime={post.date_published}>
                                    Published on {formatDate(post.date_published)}
                                </time>
                                <span> by {post.author?.name || ''}</span>
                            </div>
                        </header>
                    </article>
                ))}
            </main>
        </div>
    );
};

export default BlogPage;
